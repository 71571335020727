import React from 'react';
import { makeStyles } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    card: {
        margin: theme.spacing(2, 0),
        minWidth: "95%"
    },
}));


export default function Info(props) {

    const { appInstanceId } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper height="3">
                <Typography variant="h5" align="center">
                    Om
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    Velkommen til Nuerdenfri.dk
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    Ideen med denne side var at hjælpe med to ting:
                    <ol>
                        <li>At man kan få oplyst når en lader bliver fri, når man gerne vil lade</li>
                        <li>At fremme god ladestil og lade medbilister komme til laderen, når man er færdig med at lade</li>
                    </ol>
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    Siden er et hobbyprojekt, da vi selv kører elbil og oplevede at lede forgæves efter ladere, når vi havde brug for en.<br />
                    Derudover vil vi gerne være med til at fremme en etik om at en ladeplads bliver brugt til ladning ikke parkering.
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    Siden har en pauseperiode, som betyder at hvis man sætter sin bil til at lade efter kl 22, så bliver man ikke forstyrret af notifikationer. Alle har lov til at have fri <span role="img" aria-label="Smiley">😊</span>
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    Foreløbig checker app'en kun ladere i EON netværket en gang hvert 3. minut. Så der kan gå op til godt 3 minutter fra en lader bliver fri til du modtager en notifikation.
                </Typography>
                <Typography variant="body2" paragraph={true}>
                    Hvis du har feedback til forbedringer, oplevede fejl eller andet så skriv på <a href="mailto:nuerdenfri@gmail.com">nuerdenfri@gmail.com</a>
                </Typography>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div style={{ fontSize: "6px" }}>&nbsp;&nbsp;{(appInstanceId && appInstanceId.substring(0, 5))}</div>
            </Paper>
        </div>
    );
}
import React from 'react';
import Box from '@material-ui/core/Box';
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js-cartesian-dist/plotly-cartesian';
const PlotlyComponent = createPlotlyComponent(Plotly);


export default function SiteInfoAvailabilityHeatmap(props) {
    const { selectedSiteInfo } = props;

    return (
        <div>
            {selectedSiteInfo.loaded ? 
                <Box display="flex" justifyContent="center" mb={2} pb={2} bgcolor="background.paper">
                    {selectedSiteInfo.dataAnyFree ? 
                        <PlotlyComponent
                            data = {[
                            {
                                z: selectedSiteInfo.dataAnyFree,
                                zmax: 100,
                                zmin: 0,
                                y: ['Søndag', 'Lørdag', 'Fredag', 'Torsdag', 'Onsdag', 'Tirsdag','Mandag'],
                                x: ['-1', '1-2', '2-3','3-4','4-5','5-6','6-7','7-8','8-9','9-10','1-11','11-12','12-13','13-14','14-15','15-16','16-17','18-19','19-20','20-21','21-22','22-23','23-24'],
                                type: 'heatmap',
                                colorscale: 'YIGnBu',
                                hoverongaps: false,
                                margin: { t: 20, l: 20, r: 20, b: 20 },
                                colorbar: {title: {text: "% af tiden"}, thickness: 30, outlinewidth: 1, outlinecolor: "#CCC", tickmode: 'array', tickvals: [0,25,50,75,100], ticktext: ['0', '25', '50', '75', '100']}
                            }
                            ]}
                            layout={ {width: 390, height: 300, title: {text: 'Chance for mindst 1 fri lader', y: 0.99}, margin: {t: 30}} }
                            config={{displayModeBar: false}}
                        />
                    : ''}
                </Box>
            : ''}
        </div>
    );
}
import "@firebase/polyfill";
import './polyfills';
//import 'core-js/fn/number/is-nan';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
//import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './components/Firebase'
//serviceWorker.register({});

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      console.log(`Registration successful, registration.scope is: ${JSON.stringify(registration.scope)}`);
    })
    .catch(function(err) {
      console.log("Service worker registration failed, error:", err);
    });
}

/*The main component is being rendered to the root id element. */
ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <FirebaseContext.Consumer>
            {firebase => <App firebase={firebase}/>}
        </FirebaseContext.Consumer>
    </FirebaseContext.Provider>
    ,document.getElementById('root'))
import React from 'react';
import { useParams} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import EvStationOutlined from '@material-ui/icons/EvStationOutlined';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import SiteInfoAvailabilityHeatmap from '../SiteInfoAvailabilityHeatmap'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    card: {
        margin: theme.spacing(2, 0),
        minWidth: "95%"
    },
    subTitle: {
        paddingTop: 15
    },
    actionTitle: {
        color: "red"
    },
    center: {
        justifyContent: 'center'
    },
    badges: {
        marginRight: "12px",
        marginTop: "14px"
    },
    multipleButtons: {
        marginRight: "15px"
    }
}));
export default function SiteInfo(props) {

    const { siteId } = useParams();
    const { dataProvider, redirectTo } = props;
    const classes = useStyles();
    const [selectedSiteInfo, setSelectedSiteInfo] = React.useState([null]);

    const onClickClose = () => {
        redirectTo("/locations");
    }
    const onUpdate = () => {
        dataProvider.getSiteCurrentChargingInfo(siteId).then(si => {
            setSelectedSiteInfo(si)
        })
    }

    React.useEffect(() => {
        dataProvider.getSiteCurrentChargingInfo(siteId).then(si => {
            console.log(si);
            setSelectedSiteInfo(si)
        })
    }, [siteId]);

    return (
        <div className={classes.root}>
            <Paper height="3">
                <Typography variant="h6" align="center">
                    <br />
                    <br />
                    {selectedSiteInfo ? selectedSiteInfo.name : ''}
                </Typography>
                <Typography variant="body1" align="center">
                    {selectedSiteInfo && selectedSiteInfo.address}<br />
                    {selectedSiteInfo && selectedSiteInfo.noOfChargers} ladestandere ialt
                    {selectedSiteInfo && selectedSiteInfo.noOfChargers > 0 ? (<span><br />{selectedSiteInfo.currentChargingSessions.length} i brug for øjeblikket</span>): ''}
                </Typography>
                {!selectedSiteInfo.loaded ? 
                    <div style={{align: "center"}}><CircularProgress size="3.5rem"/></div>
                : ''}
                <Typography variant="body1" align="center">
                    {selectedSiteInfo.loaded && selectedSiteInfo.currentFreeChargers.map((ssi, i) => 
                        <Tooltip key={i} title={`En af pladserne har været fri i ca. ${ssi}`} aria-label={`En af pladserne har været fri i ca.  ${ssi}`}>
                            <Badge badgeContent={ssi} color="primary" className={classes.badges}>
                                <EvStationOutlined />
                            </Badge>
                        </Tooltip>
                    )}
                    {selectedSiteInfo.hasBothFreeAndOccupiedChargers ? <span>&nbsp;&nbsp;&nbsp;</span>:''}
                    {selectedSiteInfo.loaded && selectedSiteInfo.currentChargingSessions.map((ssi, i) => 
                        <Tooltip key={i} title={`En af pladserne har været i brug ca. ${ssi}`} aria-label={`En af pladserne har været i brug ca.  ${ssi}`}>
                            <Badge badgeContent={ssi} color="primary" className={classes.badges}>
                                <DirectionsCarOutlinedIcon />
                            </Badge>
                        </Tooltip>
                    )}
                    <br />
                    <br />
                    <br />
                    <br />
                </Typography>
                <Box display="flex" justifyContent="center" mb={2} pb={2} bgcolor="background.paper">
                    <Box p={1}>
                        <Button variant="contained" size="large" color="primary" className={classes.multipleButtons}
                            onClick={onClickClose} >
                            Luk
                        </Button>
                        <Button variant="contained" size="large" color="primary"
                            onClick={onUpdate} >
                            Opdater
                        </Button>
                    </Box>
                </Box>
                <SiteInfoAvailabilityHeatmap selectedSiteInfo={selectedSiteInfo} />
            </Paper>
        </div>
    );
}
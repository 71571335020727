import app from 'firebase';
//import app from 'firebase/app';
//import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/analytics';

class Firebase {
    constructor() {
        //let app = firebase.app();

        if (typeof app === 'undefined') throw new Error('hosting/init-error: Firebase SDK not detected. You must include it before /__/firebase/init.js');
        console.log(`Firebase.js app methods: ${JSON.stringify(Object.getOwnPropertyNames(app).filter(item => typeof app[item] === 'function'))}`);
        let initializedApp = app.initializeApp({
            "projectId": "nuerdenfri",
            "appId": "1:805939516459:web:8d700e6a6896416cb0866e",
            "databaseURL": "https://nuerdenfri.firebaseio.com",
            "storageBucket": "nuerdenfri.appspot.com",
            "locationId": "europe-west",
            "apiKey": "AIzaSyBquwRjNJXc_xx1ofUvbCsU4ojLchfJvNo",
            "authDomain": "nuerdenfri.firebaseapp.com",
            "messagingSenderId": "805939516459",
            "measurementId": "G-64GMLXFRZK"
        });


        this.doCallAnalytics = true;

        let features = ['auth', 'database', 'messaging', 'storage'].filter(feature => typeof app[feature] === 'function');
        console.log(`Firestore.js constructor: Firebase SDK loaded with ${features.join(', ')}`);
        try {
            this.db = app.firestore(initializedApp);
            this.firestore = app.firestore();
            this.analytics = app.analytics();
            this.messaging = app.messaging();
        } catch (e) {
            console.log(`Got error trying to use Firebase messaging: ${JSON.stringify(e)}`);
            console.log(`Setting mock messaging firebase obj`);
            this.messaging = {
                isMock: true,
                onMessage: (payload) => { console.log(`Received message payload from mock message api: ${payload}`);},
                requestPermission: () => { return new Promise((resolve, reject) => {
                    reject("Mock response from requestPermission");
                }); },
                getToken: () => { return new Promise((resolve, reject) => {
                    resolve("MockTokenFromBrowserNotSuportingIt");
                }); },
            };
        }

        this.iWantToChargeNotifications = null;
        this.setIWantToChargeNotifications = null;
        this.iWantToChargeSettings = null;
        this.setIWantToChargeSettings = null;
        this.iAmChargingNotifications = null
        this.setIAmChargingNotifications = null;

        this.siteStatusUnsubscribes = [];
        this.siteStatusShortsOld = null; 

        this.collectionSubscriptions = null;
        this.collectionIAmChargingSubscriptions = null;
        this.setupFirestoreSnapshotListeners.bind(this);
        this.getAppInstanceId().then(appInstanceId => {
            this.setupFirestoreSnapshotListeners(appInstanceId);
        });
    }

    updateSiteStatusShortListeners = () => {

        if(!this.messaging.isMock === true)
            return;

        // unsubscribe from previous
        this.siteStatusUnsubscribes.forEach(unsubscribe => {
            unsubscribe();
        });
        this.siteStatusShortsOld = {};


        let siteIds = [];
        if( this.collectionSubscriptions ) siteIds.push(...Object.keys(this.collectionSubscriptions).filter(si => si !== "settings" && !this.collectionSubscriptions[si].disabled));
        if( this.collectionIAmChargingSubscriptions ) siteIds.push(...Object.keys(this.collectionIAmChargingSubscriptions).filter(si => si !== "settings" && !this.collectionIAmChargingSubscriptions[si].disabled));
        console.log(`updateSiteStatusShortListeners: siteIds: ${siteIds.join(", ")}`);
        // 1. Extract siteIds from 
        //    this.collectionSubscriptions
        //    this.collectionIAmChargingSubscriptions

        // 2. Remove old sites that are no more subscribed to
        Object.keys(this.siteStatusShortsOld).forEach(siteId => {
            if(!siteIds.includes(siteId)) delete this.siteStatusShortsOld[siteId];
        });

        // 3. siteStatusShort onSnapshort callback for all sites:
        //     3.a Compare if there is an old version of siteShatusShort
        //         if match of currentFreeChargers increase and site in this.collectionSubscriptions - call showMessageCallback (set earlier, perhaps constructor)
        //         if match of iWantToChargeIds increase and site in this.collectionIAmChargingSubscriptions - call showMessageCallback (set earlier, perhaps constructor)
        //     3.b Save the current siteStatus to this.siteStatusShortsOld
        this.siteStatusUnsubscribes = siteIds.map(siteId => {
            return this.db.collection('siteStatusShort').doc(siteId)
                .onSnapshot(doc => {
                    const siteId = doc.id;
                    let docData = doc.data();
                    if( this.siteStatusShortsOld[doc.id] ) {

                        // Check for new free changer
                        if( this.collectionSubscriptions[siteId] 
                            && docData.currentFreeChargers.length > this.siteStatusShortsOld[siteId].currentFreeChargers.length )
                            {
                                console.log(`updateSiteStatusShortListeners: For siteId: ${siteId} One more free charger !!!!!!!!!!!`);
                                this.newFreeChargerCallback(siteId, docData);
                            }

                        // Check for new person waiting for free charger
                        if( this.collectionIAmChargingSubscriptions[siteId] 
                            && docData.iWantToChargeIds && docData.iWantToChargeIds.length > this.siteStatusShortsOld[siteId].iWantToChargeIds.length )
                            {
                                console.log(`updateSiteStatusShortListeners: For siteId: ${siteId} One more person waiting for free charger !!!!!!!!!!!`);
                                this.newIWantToChargeCallback(siteId, docData);
                            }
                    }
                    console.log(`updateSiteStatusShortListeners: For siteId: ${siteId} Saving new siteStatusShort: ${JSON.stringify(docData)}`);
                    this.siteStatusShortsOld[siteId] = docData;
                    if( !this.siteStatusShortsOld[siteId].iWantToChargeIds ) this.siteStatusShortsOld[siteId].iWantToChargeIds = [];
                });
        });
    }

    updateIWantToChargeNotificationsFromCollectionSubscriptions = () => {
        console.log(`updateIWantToChargeNotificationsFromCollectionSubscriptions called`);
        if(this.setIWantToChargeNotifications && this.collectionSubscriptions) {
            const iWantToChargeLocations = Object.keys(this.collectionSubscriptions)
                .filter(e => e !== "settings")
                .map(e => this.collectionSubscriptions[e])
                .sort((a,b) => a.siteName > b.siteName);
            console.log(`updateIWantToChargeNotificationsFromCollectionSubscriptions - updating with new data: ${iWantToChargeLocations}`);
            this.setIWantToChargeNotifications(iWantToChargeLocations);
            this.setIWantToChargeSettings(this.collectionSubscriptions["settings"]);
        }
    }

    updateIAmChargingNotificationsFromCollectionIAmChargingSubscriptions = () => {
        console.log(`updateIAmChargingNotificationsFromCollectionIAmChargingSubscriptions called`);
        if(this.setIAmChargingNotifications && this.collectionIAmChargingSubscriptions) {
            const iAmChargingNotifications = Object.keys(this.collectionIAmChargingSubscriptions)
                .filter(e => e !== "settings")
                .map(e => this.collectionIAmChargingSubscriptions[e])
                .sort((a,b) => a.siteName > b.siteName);
            console.log(`updateIAmChargingNotificationsFromCollectionIAmChargingSubscriptions - updating with new data: ${JSON.stringify(iAmChargingNotifications)}`);
            this.setIAmChargingNotifications(iAmChargingNotifications);
        }
    }

    setupFirestoreSnapshotListeners = (appInstanceId) => {
        this.db.collection("subscriptions").doc(appInstanceId)
            .onSnapshot(function(doc) {
                var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
                console.log("onSnapshot: ", source, " data: ", doc.data());
                this.collectionSubscriptions = doc.data();
                this.updateIWantToChargeNotificationsFromCollectionSubscriptions();
                this.updateSiteStatusShortListeners();
            }.bind(this));
        this.db.collection("iAmChargingSubscriptions").doc(appInstanceId)
            .onSnapshot(function(doc) {
                var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
                console.log("onSnapshot: ", source, " data: ", doc.data());
                this.collectionIAmChargingSubscriptions = doc.data();
                this.updateIAmChargingNotificationsFromCollectionIAmChargingSubscriptions()
                this.updateSiteStatusShortListeners();
            }.bind(this));

    }

    setNotificationsUseStateFunctions = (appInstanceId, 
        iWantToChargeNotifications, setIWantToChargeNotifications, 
        iWantToChargeSettings, setIWantToChargeSettings, 
        iAmChargingNotifications, setIAmChargingNotifications,
        newFreeChargerCallback, newIWantToChargeCallback) => {
        this.iWantToChargeNotifications = iWantToChargeNotifications;
        this.setIWantToChargeNotifications = setIWantToChargeNotifications;
        this.iWantToChargeSettings = iWantToChargeSettings;
        this.setIWantToChargeSettings = setIWantToChargeSettings;
        this.iAmChargingNotifications = iAmChargingNotifications;
        this.setIAmChargingNotifications = setIAmChargingNotifications;
        if(!this.iWantToChargeNotifications || !this.iWantToChargeSettings)
            this.updateIWantToChargeNotificationsFromCollectionSubscriptions();
        if(!this.iAmChargingNotifications)
            this.updateIAmChargingNotificationsFromCollectionIAmChargingSubscriptions();
        this.newFreeChargerCallback = newFreeChargerCallback;
        this.newIWantToChargeCallback = newIWantToChargeCallback;
    }

    getAppInstanceId = () => {
        return new Promise((resolve, reject) => {

            const localStorageKey = "appInstanceId";
            let appInstanceId = localStorage.getItem(localStorageKey);

            if (appInstanceId) {
                console.log("Loaded appInstanceId from promise: ", appInstanceId);
                resolve(appInstanceId);
                return;
            }

            const instanceIdPromise = fetch('/getInstanceId');
            instanceIdPromise.then(resp => {
                resp.json().then(json => {
                    console.log("Got instanceId: ", json.instanceId);
                    localStorage.setItem(localStorageKey, json.instanceId);
                    resolve(json.instanceId);
                });
            });
        });
    }

    setDoCallAnalytics = (value) => {
        this.doCallAnalytics = value;
    }

    logAnalyticsEvent = (eventName, parameters = null) => {
        if(this.doCallAnalytics)
            this.analytics.logEvent(eventName, parameters);
    }

    setOnMessageCallback = (callback) => {
        this.messaging.onMessageCallback = callback;
    }

    requestPermission = () => {
        return this.messaging.requestPermission();
    }

    getToken = () => {
        return this.messaging.getToken(true);
    }
}

export default Firebase;
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import calcCrow from '../../utils/geo';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import Paper from '@material-ui/core/Paper';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import MyLocation from '@material-ui/icons/MyLocation';
import LocationDisabled from '@material-ui/icons/LocationDisabled';
import Box from '@material-ui/core/Box';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Chip from '@material-ui/core/Chip';

import CircularProgress from '@material-ui/core/CircularProgress';
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import EvStationOutlined from '@material-ui/icons/EvStationOutlined';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import SiteInfoAvailabilityHeatmap from '../SiteInfoAvailabilityHeatmap'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    card: {
        margin: theme.spacing(2, 0),
        minWidth: "95%"
    },
    subTitle: {
        paddingTop: 15
    },
    actionTitle: {
        color: "red"
    },
    center: {
        justifyContent: 'center'
    },
    badges: {
        marginRight: "12px",
        marginTop: "14px"
    },
    multipleButtons: {
        marginRight: "15px"
    }
}));

export default function LocationWizard(props) {

    const classes = useStyles();
    const [labelWidth, setLabelWidth] = React.useState(0);
    const { dataProvider, onSelectIWantToCharge, onSelectIAmCharging, onSelectCancelSelectingSite } = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedSite, setSelectedSite] = React.useState(null);
    const [selectedSiteInfo, setSelectedSiteInfo] = React.useState([null]);
    const [selectedAction, setSelectedAction] = React.useState(null);
    const [allSites, setAllSites] = React.useState(null);
    const [filteredSites, setFilteredSites] = React.useState([]);
    const [searchString, setSearchString] = React.useState("");
    const [currentLocation, setCurrentLocation] = React.useState(null);
    const localStorageKey_okToAskForPositionAccess = "okToAskForPositionAccess";

    const labelRef = React.useRef(null);

    React.useEffect(() => {
        if (labelRef.current)
            setLabelWidth(labelRef.current.offsetWidth);
    }, []);

    const onChangeSearchString = (e) => {
        handleNewSearchString(e.target.value);
    };

    React.useEffect(() => {
        const okToAskForPositionAccess = localStorage.getItem(localStorageKey_okToAskForPositionAccess);
        dataProvider.getAllSites()
            .then((sites) => {
                if(okToAskForPositionAccess !== "true") {
                    setCurrentLocation(null);
                    setAllSites(sites.sites);
                    handleNewSearchString("", sites.sites);
                    return;
                }
                navigator.geolocation.getCurrentPosition((pos) => {
                    var crd = pos.coords;
                    setCurrentLocation(crd);
                    sites.sites.forEach(s => s.distToCurrentPos = calcCrow(crd.latitude, crd.longitude, s.lat, s.lng));
                    setAllSites(sites.sites);
                    handleNewSearchString("", sites.sites);
                }, (err) => {
                    console.warn(`ERROR(${err.code}): ${err.message}`);
                    setCurrentLocation(null);
                    setAllSites(sites.sites);
                    handleNewSearchString("", sites.sites);
                }, { enableHighAccuracy: true, timeout: 5000, maximumAge: 0
                });
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNewSearchString = (str, newAllSites = null) => {
        setSearchString(str);

        const searchStringParts = str.toLocaleLowerCase().split(" ");
        const noOfParts = searchStringParts.length;
        const allSitesOrCurrentAllSites = newAllSites || allSites;
        let tmpFilteredSites = str.length === 0 ? allSitesOrCurrentAllSites : allSitesOrCurrentAllSites.filter(s => noOfParts === searchStringParts.filter(ssp => [s.name, s.address].join(" ").toLocaleLowerCase().indexOf(ssp) > -1).length);

        console.log(`handleNewSearchString`);
        if (tmpFilteredSites.length > 0 && tmpFilteredSites[0].distToCurrentPos) {
            console.log("has .distToCurrentPos");
            tmpFilteredSites = tmpFilteredSites.sort((s1, s2) => s1.distToCurrentPos - s2.distToCurrentPos);
        }
        else {
            console.log("has NO .distToCurrentPos");
            tmpFilteredSites = tmpFilteredSites.sort((s1, s2) => [s1.name, s1.address].join(" ").trim().toLocaleLowerCase() > [s2.name, s2.address].join(" ").trim().toLocaleLowerCase() ? 1 : -1);
        }

        setFilteredSites(tmpFilteredSites.slice(0, 24));
    }


    const handleLocationAction = (locationIsEnabled) => {
        console.log(`handleLocationAction(${locationIsEnabled})`);
        localStorage.setItem(localStorageKey_okToAskForPositionAccess, "true");
        if (!locationIsEnabled) {
            setCurrentLocation(null);
            allSites.forEach(s => (s.distToCurrentPos = null));
            handleNewSearchString(searchString, allSites);
            console.log("Disabled location");
        } else {
            var options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            };

            let tmpAllSites = allSites;
            function success(pos) {
                var crd = pos.coords;
                setCurrentLocation(crd);

                console.log('Your current position is:');
                console.log(`Latitude : ${crd.latitude}`);
                console.log(`Longitude: ${crd.longitude}`);
                console.log(`More or less ${crd.accuracy} meters.`);

                if(tmpAllSites) {
                    tmpAllSites.forEach(s => s.distToCurrentPos = calcCrow(crd.latitude, crd.longitude, s.lat, s.lng));
                    setAllSites(tmpAllSites);
                    handleNewSearchString(searchString, tmpAllSites);
                }
            }

            function error(err) {
                console.warn(`ERROR(${err.code}): ${err.message}`);
                setCurrentLocation(null);
            }

            navigator.geolocation.getCurrentPosition(success, error, options);
        }
    }

    const onClickActiveStep = (clickedStep) => {
        if (clickedStep === 0 || (clickedStep > 0 && selectedAction) || (clickedStep > 1 && selectedSite))
            setActiveStep(clickedStep);
    }
    const onClickSelectAction = (clickedAction) => {
        setSelectedAction(clickedAction);
        setActiveStep(1);
        handleLocationAction(true);
    }

    const onClickSite = (site) => {
        console.log(`onClickSite = (${JSON.stringify(site)})`);
        setSelectedSiteInfo([])
        setSelectedSite(site);
        setActiveStep(2);
        dataProvider.getSiteCurrentChargingInfo(site.id).then(si => {
            setSelectedSiteInfo(si)
        })

    }

    const onClickCancelSelectingSite = () => {
        onSelectCancelSelectingSite();
    }

    const onSelectIAmChargingSite = (site) => {
        const location = { siteId: site.id, siteName: site.name, siteAddress: site.address };
        (selectedAction === 'iWantToCharge' ? onSelectIWantToCharge : onSelectIAmCharging)(location)
    }

    const formatDistance = (distKm) => {
        if (distKm > 10)
            return Math.round(distKm) + "km";
        if (distKm > 1)
            return Math.round(distKm * 10) / 10 + "km";

        return Math.round(distKm * 1000) + "m";
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {["Venter el. Lader", "Sted", "Ok"].map((label, i) => (
                    <Step key={label}>
                        <StepLabel onClick={onClickActiveStep.bind(this, i)}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {activeStep === 0 ?
                <div>
                    <Card className={classes.card}>
                        <CardActions className={classes.center}>
                            <Button variant="contained" size="large" color="primary"
                                onClick={onClickSelectAction.bind(this, 'iWantToCharge')}
                            >
                                Jeg vil gerne lade
                            </Button>
                        </CardActions>
                        <CardContent>
                            <Typography component="h3" variant="h6" align="center">
                                Giv besked næste gange der bliver en lader fri
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card className={classes.card} >
                        <CardActions className={classes.center}>
                            <Button variant="contained" size="large" color="primary"
                                onClick={onClickSelectAction.bind(this, 'iAmCharging')}
                            >
                                Jeg lader
                            </Button>
                        </CardActions>
                        <CardContent>
                            <Typography component="h3" variant="h6" align="center">
                                Giv besked hvis der er en anden der venter på at lade
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                : ''}
            {activeStep === 1 ?
                <Paper height="3">
                    <Typography variant="h5" align="center">
                        {selectedAction === 'iWantToCharge' ? 'Jeg vil gerne lade ved' : 'Jeg lader ved'}
                    </Typography>

                    {!allSites ?
                        <div>
                            <Box display="flex" justifyContent="center" m={0} p={0} bgcolor="background.paper">
                                <Box p={1}>
                                    <div><CircularProgress /></div>
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="center" m={0} p={0} bgcolor="background.paper">
                                <Box p={1}>
                                    <Typography variant="body2" align="center">
                                        Henter ladesteder
                                    </Typography>
                                </Box>
                            </Box>
                        </div>
                        :
                        <div>
                            <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
                                <Box p={1}>
                                    <form className={classes.root} noValidate autoComplete="off">
                                        <FormControl variant="outlined">
                                            <InputLabel ref={labelRef} htmlFor="component-outlined">
                                                Postnr/By/Gade/Navn
                                            </InputLabel>
                                            <OutlinedInput
                                                id="component-outlined"
                                                labelWidth={labelWidth}
                                                value={searchString}
                                                onChange={onChangeSearchString}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        {currentLocation ? <MyLocation onClick={handleLocationAction.bind(this, false)} /> : <LocationDisabled onClick={handleLocationAction.bind(this, true)} />}
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </form>
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="center" mt={-3} m={0} p={0} bgcolor="background.paper">
                                <Box p={1}>
                                    <List className={classes.root}>
                                        {filteredSites.map((s,i) =>
                                            <ListItem key={i}>
                                                {s.distToCurrentPos ?
                                                    <ListItemAvatar>
                                                        <Box mr={1}>
                                                            <Chip label={formatDistance(s.distToCurrentPos)} />
                                                        </Box>
                                                    </ListItemAvatar>
                                                    : ''}
                                                <ListItemText primary={s.name} secondary={s.address} onClick={onClickSite.bind(this, s)} />
                                            </ListItem>
                                        )}
                                    </List>
                                </Box>
                            </Box>
                        </div>
                    }
                </Paper>
                : ''}
            {activeStep === 2 ?
                <div>
                    <Paper height="3">
                        <br />
                        <br />
                        <Typography variant="h5" align="center">
                            {selectedAction === 'iWantToCharge' ? 'Jeg vil gerne lade ved' : 'Jeg lader ved'}
                        </Typography>
                        <Typography variant="body1" align="center">
                            ─────<br />
                        </Typography>
                        <Typography variant="h6" align="center">
                            {selectedSite.name}
                        </Typography>
                        <Typography variant="body1" align="center">
                            {selectedSite.address}<br />
                            {selectedSite.nocs} ladestandere ialt
                            {selectedSiteInfo && selectedSiteInfo.noOfChargers > 0 ? (<span><br />{selectedSiteInfo.currentChargingSessions.length} i brug for øjeblikket</span>): ''}
                        </Typography>
                        {!selectedSiteInfo.loaded ? 
                            <div style={{align: "center"}}><CircularProgress size="3.5rem"/></div>
                        : ''}
                        <Typography variant="body1" align="center">
                            {selectedSiteInfo.loaded && selectedSiteInfo.currentFreeChargers.map((ssi, i) => 
                                <Tooltip key={i} title={`En af pladserne har været fri i ca. ${ssi}`} aria-label={`En af pladserne har været fri i ca.  ${ssi}`}>
                                    <Badge badgeContent={ssi} color="primary" className={classes.badges}>
                                        <EvStationOutlined />
                                    </Badge>
                                </Tooltip>
                            )}
                            {selectedSiteInfo.hasBothFreeAndOccupiedChargers ? <span>&nbsp;&nbsp;&nbsp;</span>:''}
                            {selectedSiteInfo.loaded && selectedSiteInfo.currentChargingSessions.map((ssi, i) => 
                                <Tooltip key={i} title={`En af pladserne har været i brug ca. ${ssi}`} aria-label={`En af pladserne har været i brug ca.  ${ssi}`}>
                                    <Badge badgeContent={ssi} color="primary" className={classes.badges}>
                                        <DirectionsCarOutlinedIcon />
                                    </Badge>
                                </Tooltip>
                            )}
                        </Typography>
                        <Typography variant="body1" align="center">
                            ─────<br />
                        </Typography>
                        <Typography variant="body1" align="center">
                            {selectedAction === 'iWantToCharge' ? 'Få en notifikation næste gang en lader bliver fri' : 'Få en notifikation hvis der er en der venter på at lade'}
                        </Typography>
                        <Box display="flex" justifyContent="center" mb={2} pb={2} bgcolor="background.paper">
                            <Box p={1}>
                                <Button variant="contained" size="large" color="secondary" className={classes.multipleButtons}
                                    onClick={onClickCancelSelectingSite} >
                                    Luk
                                </Button>
                                <Button variant="contained" size="large" color="primary"
                                    onClick={onSelectIAmChargingSite.bind(this, selectedSite)} >
                                    OK
                                </Button>
                            </Box>
                        </Box>
                        <br />
                        <SiteInfoAvailabilityHeatmap selectedSiteInfo={selectedSiteInfo} />
                        <br />
                        <br />
                        <br />
                    </Paper>
                </div>
                : ''}
        </div>
    );
}
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    title: {
        margin: theme.spacing(1, 2, 0),
    },
    popover: {
        pointerEvents: 'none',
        padding: "25px"
    },
}));

/*It is created as a component function in the react hooks.*/
function NotificationsList(props) {

    const classes = useStyles();
    const { iWantToChargeNotifications, iAmChargingNotifications, removeNotificationLocation, disableNotificationLocation, isLoading: isLoadingNotifications, onClickShowSiteInfo } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    let open = Boolean(anchorEl);

    const iWantToChargeNotificationListRef = React.useRef();

    React.useEffect(() => {
        const justStartedNotification = iWantToChargeNotifications.find(n => n.justAdded === true);
        if(justStartedNotification)
            setAnchorEl(iWantToChargeNotificationListRef.current);
    }, [iWantToChargeNotifications]);

    const handlePopoverClose = () => {
        console.log("Clicked handlePopoverClose");
        setAnchorEl(null);
        open = false;
    };

    return (
        <div>
            {isLoadingNotifications ? <CircularProgress /> : ""}
            {!isLoadingNotifications && iWantToChargeNotifications && iWantToChargeNotifications.length > 0 ? 
                <Paper className={classes.root}>
                    <Typography variant="h6" className={classes.title}>
                        Få besked ved fri lader
                    </Typography>
                    <List ref={iWantToChargeNotificationListRef}>
                        {iWantToChargeNotifications && iWantToChargeNotifications
                            .sort((s1, s2) => [s1.siteName, s1.siteAddress].join(" ").trim().toLocaleLowerCase() > [s2.siteName, s2.siteAddress].join(" ").trim().toLocaleLowerCase() ? 1 : -1)
                            .map(site =>
                            <ListItem key={site.siteId}>
                                <ListItemIcon>
                                    <IconButton edge="end" aria-label="delete" onClick={removeNotificationLocation.bind(this, site, true)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText style={{ paddingRight: "5px" }} primary={site.siteName} secondary={site.siteAddress}
                                    onClick={onClickShowSiteInfo.bind(this, site)}
                                />
                                <ListItemSecondaryAction>
                                    <Switch
                                        edge="end"
                                        onChange={disableNotificationLocation.bind(this, site, true)}
                                        checked={!site.disabled}
                                        inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                </Paper>
            : ""}

            {!isLoadingNotifications && iAmChargingNotifications && iAmChargingNotifications.length > 0 ?
                <Paper className={classes.root}>
                    <Typography variant="h6" className={classes.title}>
                        Få besked hvis medbilist venter
                    </Typography>
                    <List>
                        {iAmChargingNotifications && iAmChargingNotifications.map(site =>
                            <ListItem key={site.siteId}>
                                <ListItemIcon>
                                    <IconButton edge="end" aria-label="delete" onClick={removeNotificationLocation.bind(this, site, false)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText style={{ paddingRight: "5px" }} primary={site.siteName} secondary={site.siteAddress}
                                    onClick={onClickShowSiteInfo.bind(this, site)}
                                />
                                <ListItemSecondaryAction>
                                    <Switch
                                        edge="end"
                                        onChange={disableNotificationLocation.bind(this, site, false)}
                                        checked={!site.disabled}
                                        inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                </Paper>
            : ""}
            {false ?
            <Popover
                id="sound-volume-reminder-popover"
                className={classes.popover}
                classes={{ paper: classes.paper }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                onClose={handlePopoverClose} >
                <div style={{padding: "25px", align: "center"}}>
                    <Typography paragraph={true} align="center"><VolumeUpRoundedIcon /></Typography>
                    <Typography paragraph={true} align="center">Husk at skrue op for lyden så du ikke får glip af nu-er-den-fri-beskeden.<br /></Typography>

                        <Box display="flex" justifyContent="center" mb={2} pb={2} bgcolor="background.paper">
                            <Box p={1} onClick={handlePopoverClose.bind(this)}>
                                <Button variant="contained" size="large" color="primary"
                                    onClick={handlePopoverClose.bind(this)} >
                                    OK
                                </Button>
                            </Box>
                        </Box>



                </div>
            </Popover>
            : ''}
        </div>
    )
}

export default NotificationsList /*we export to access other files.*/
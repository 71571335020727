import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function MessageDialog(props){

    const audioRef = React.createRef();
    const handleClose = () => {
        props.onClose && props.onClose();
    };

    useEffect(() => {
        if(props.ringBell)
            audioRef.current.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.body.split('\n\n').map((item, key) => {
                        return <React.Fragment key={key}>{item}<br/><br /></React.Fragment>
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
            </Dialog>
            <audio ref={audioRef}>   
                <source src="bell.mp3" />   
            </audio> 
        </div>
    );

}
import React, { useEffect, useCallback } from 'react';
import fetchJsonp from 'fetch-jsonp';

import { BrowserRouter as Router,Switch,Route} from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Fab from '@material-ui/core/Fab';
import AddOutlined from '@material-ui/icons/AddOutlined';

import LocationWizard from '../LocationWizard';
import NotificationsList from '../NotificationsList';
import DataProvider from '../../providers/dataProvider';
import MessageDialog from '../MessageDialog';
import Info from '../Info';
import SiteInfo from '../SiteInfo';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MessageEnterEmailDialog from '../MessageEnterEmailDialog';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function App(props) {

  const firebase = props.firebase;
  const dataProvider = new DataProvider(firebase);
  const classes = useStyles();
  const theme = useTheme();
  const [iWantToChargeNotifications, setIWantToChargeNotifications] = React.useState([]);
  const [iAmChargingNotifications, setIAmChargingNotifications] = React.useState([]);
  const [iWantToChargeSettings, setIWantToChargeSettings] = React.useState([]);

  const [appInstanceId, setAppInstanceId] = React.useState(null);
  const [messageDialog, setMessageDialog] = React.useState(null);
  const [enterEmailMessageDialog, setEnterEmailMessageDialog] = React.useState(null);
  const [isLoadingNotifications, setIsLoadingNotifications] = React.useState(true);

  const [appIsJustLoaded, setAppIsJustLoaded] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const routerRef = React.useRef(null);

  const wrapUseStateSet = (method) => {
    return (val) => {
      console.log(`Called wrapUseStateSet with appIsJustLoaded: ${appIsJustLoaded}, val: ${JSON.stringify(val)}`);
      if(appIsJustLoaded) {
        setAppIsJustLoaded(false);
        if(val.length > 0) {
          redirectTo("/locations");
          console.log("Redirected to /locations")
          console.log("firebase.messaging.isMock: " + firebase.messaging.isMock);
        }
      }
      method(val);
    };
  }

  const newFreeChargerCallback = (siteId, docData) => {
    //alert(`newFreeChargerCallback: ${JSON.stringify(docData)}`);
    const title = `${docData.currentFreeChargers.length} ladere er nu frie ved ${docData.name}`;
    const body = `Antallet af frie opladere ved ${docData.name} er nu ${docData.currentFreeChargers.length}`;
    const payload = {
      notification: {
        title: title,
        body: body
      }, 
      data:{notificationType: "NOTIFICATIONTYPE_CHARGERCHANGE"}
    };
    firebase.messaging.onMessageCallback(payload)
    const req = { iWantToCharge: true, appInstanceId: appInstanceId, siteId: siteId, disabled: true };
    dataProvider.setDisabledNotificationLocation(req);
  };

  const newIWantToChargeCallback = (siteId, docData) => {
    //alert(`newIWantToChargeCallback: ${JSON.stringify(docData)}`);
    const title = `En medbilist venter på der bliver plads til at lade ved ${docData.name}`;
    const body = `Når du eller en anden forlader dette sted, får vedkommende en besked om der nu er blevet en lader fri.`;
    const payload = {
      notification: {
        title: title,
        body: body
      }, 
      data:{notificationType: "NOTIFICATIONTYPE_CHARGERCHANGE"}
    };
    firebase.messaging.onMessageCallback(payload)

    const req = { iWantToCharge: false, appInstanceId: appInstanceId, siteId: siteId, disabled: true };
    dataProvider.setDisabledNotificationLocation(req);
  }

  firebase.setNotificationsUseStateFunctions(appInstanceId, 
    iWantToChargeNotifications, wrapUseStateSet(setIWantToChargeNotifications),
    iWantToChargeSettings, setIWantToChargeSettings, 
    iAmChargingNotifications,  wrapUseStateSet(setIAmChargingNotifications),
    newFreeChargerCallback, newIWantToChargeCallback);

  if(iWantToChargeSettings && iWantToChargeSettings.disableAnalyticsCalls)
    firebase.setDoCallAnalytics(false);

  useEffect(() => {
    console.log("from App/index - useEffect - calling firebase.setOnMesageCallback");
    firebase.setOnMessageCallback((payload) => {
      console.log('================================> 2 Message received from app. - show modal', payload);
      const n = payload.notification;
      let messageObj = { title: n.title, body: n.body, ringBell: true, onClose: () => { setMessageDialog(null) } };
      if (messageDialog && messageObj.body && !messageObj.body.endsWith(messageDialog.body)) {
        messageObj.body = `${messageObj.body}\n\n${messageDialog.body}`;
      }
      setMessageDialog(messageObj);
      if (payload.data && payload.data.notificationType === "NOTIFICATIONTYPE_CHARGERCHANGE")
        setIsLoadingNotifications(true);
      firebase.logAnalyticsEvent(`Notification_DisplayedImmediately`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInitialMessage = useCallback(() => {
    try {
      const hash = document.location.hash;
      const messageFromHash = hash.match(/\/m\/([^/]+)/);
      if (messageFromHash) {
        document.location.hash = hash.replace(`/m/${messageFromHash[1]}`, "");
        redirectTo('/locations');
        const data = JSON.parse(atob(messageFromHash[1]));
        setIsLoadingNotifications(true);
        setMessageDialog({ title: `⚡🔌 ${data.title}`, body: data.body, ringBell: true, onClose: () => { setMessageDialog(null) } });
        firebase.logAnalyticsEvent(`Notification_DisplayedAfterClick`);
      }
    } catch (err) {
      const errMsg = `ERROR: setOnMessageCallback. Document.location: ${JSON.serialize(document.location)}. Exception: ${JSON.serialize(err)}`;
      console.log(errMsg);
      alert(errMsg);
      dataProvider.logError(errMsg, null, null, null, null);
    }
  }, [dataProvider, firebase]);

  window.onhashchange = handleInitialMessage;

  const redirectTo = (url) => {
    console.log(`redirectTo: ${url}.`);
    routerRef.current.history.push(url);
  }

  useEffect(() => {
    console.log(`routerRef: ${JSON.stringify(Object.keys(routerRef.current))}`);
    firebase.logAnalyticsEvent('AppStarted');
    handleInitialMessage();
    console.log("UseEffect - App - Starting <<<<============== ");
    console.log("Calling getAppInstane")
    dataProvider.getAppInstanceId()
      .then((appInstanceId) => {
        console.log("getAppInstane then got called");
        setAppInstanceId(appInstanceId);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (url) => {
    if(url)
    {
      redirectTo(url);
      firebase.logAnalyticsEvent(`Menu_chose_${url.substring(1)}`);
    }

    setAnchorEl(null);
  };

  const offerEmailNotificationIfNeeded = () => {
      if(firebase.messaging.isMock && !iWantToChargeSettings.notificationEmail) {
        const body = "Denne browser kan ikke modtage beskeder mens den er lukket (i modsætning til browsere på Android of på PC/Mac laptop).\nIndtast evt. en email adresse for at få besked om fri lader på denne måde.\nBemærk: du skal klikke på linket i bekræftelsesmailen før nuerdenfri.dk begynder at sende notifikationer til emailadressen.";
        let messageObj = { title: "Få email besked ved fri lader", body: body, ringBell: false, onClose: (emailAddress) => { 
          if(emailAddress && emailAddress.trim().length > 0)
            dataProvider.setEmail(appInstanceId, emailAddress.trim());
          setEnterEmailMessageDialog(null);
        } };
        setEnterEmailMessageDialog(messageObj);
      }
  }

  const onSelectIWantToCharge = (site) => {
    console.log("onSelectIWantToCharge called");
    firebase.logAnalyticsEvent('IWantToCharge_Add');
    const aii = appInstanceId;
    const req = { iWantToCharge: true, appInstanceId: aii, siteId: site.siteId, disabled: false, createdDateTime: (new Date()).toISOString(), siteName: site.siteName, siteAddress: site.siteAddress, notificationToken: null };

    let iwtcn = iWantToChargeNotifications.find(n => n.siteId === site.siteId);
    if (iwtcn)
    {
      iwtcn.disabled = false;
      iwtcn.justAdded = true;
    }
    else
      iWantToChargeNotifications.push({...req, justAdded: true});

    setIWantToChargeNotifications(iWantToChargeNotifications);

    console.log("dataProvider.withNotificationToken");
    dataProvider.withNotificationToken((notificationToken) => {
      console.log(`onSelectIWantToCharge notificationToken: ${notificationToken}`);
      req.notificationToken = notificationToken;
      dataProvider.addNotificationLocation(req, () => {
        console.log("Called server side addNotificaionLocation (for iWantToCharge)");
      });
      offerEmailNotificationIfNeeded();
    });

    redirectTo('/locations');
  }

  const onSelectCancelSelectingSite = () => {
    redirectTo('/locations');
  }

  const onSelectIAmCharging = (site) => {
    firebase.logAnalyticsEvent('IAmCharging_Add');
    console.log(`Called onSelectIAmChargin with site: ${JSON.stringify(site)}`);
    const aii = appInstanceId;
    const req = { iWantToCharge: false, appInstanceId: aii, siteId: site.siteId, disabled: false, createdDateTime: (new Date()).toISOString(), siteName: site.siteName, siteAddress: site.siteAddress, notificationToken: null };

    let iaml = iAmChargingNotifications.find(n => n.siteId === site.siteId);
    if (iaml)
      iaml.disabled = false;
    else
      iAmChargingNotifications.push(req);

    setIAmChargingNotifications(iAmChargingNotifications);

    dataProvider.withNotificationToken((notificationToken) => {
      req.notificationToken = notificationToken;
      dataProvider.addNotificationLocation(req, () => {
        console.log("Called server side addNotificationLocation (for iAmChargin)");
      });
      offerEmailNotificationIfNeeded();
    });

    redirectTo('/locations');
  }


  const removeNotificationLocation = (site, iWantToCharge) => {
    firebase.logAnalyticsEvent(`${(iWantToCharge ? "IWantToCharge" : "IAmCharging")}_Remove`);
    const req = { iWantToCharge: iWantToCharge, appInstanceId: appInstanceId, siteId: site.siteId };

    let locations = (iWantToCharge ? iWantToChargeNotifications : iAmChargingNotifications);
    const newTmpLocations = locations.filter(s => s.siteId !== site.siteId);

    if (iWantToCharge)
      setIWantToChargeNotifications(newTmpLocations);
    else
      setIAmChargingNotifications(newTmpLocations);

    dataProvider.removeNotificationLocation(req)
      .then(() => {
        console.log("Called server side removeNotificationLocation");
      });
  }

  const disableNotificationLocation = (site, iWantToCharge) => {

    console.log(`disableNotificationLocation(${site.siteId}, iWantToCharge:${iWantToCharge ? "true" : "false"})`);

    let locations = (iWantToCharge ? iWantToChargeNotifications : iAmChargingNotifications);
    const location = locations.find(s => s.siteId === site.siteId);
    firebase.logAnalyticsEvent(`${(iWantToCharge ? "IWantToCharge" : "IAmCharging")}_${(location.disabled ? "Enable" : "Disable")}`);
    const req = { iWantToCharge: iWantToCharge, appInstanceId: appInstanceId, siteId: site.siteId, disabled: !location.disabled };

    const newTmpLocations = locations.map(s => {
      if (s.siteId === site.siteId)
      {
        s.justAdded = s.disabled;
        s.disabled = !s.disabled;
      }
      return s;
    })

    if (iWantToCharge)
      setIWantToChargeNotifications(newTmpLocations);
    else
      setIAmChargingNotifications(newTmpLocations);

    dataProvider.setDisabledNotificationLocation(req)
      .then(() => {
        console.log("Called server side setDisabledNotificationLocation");
      }, error => {
        console.log(`Error when calling setDisaledNotificationLocatino. Error: ${JSON.stringify(error)}`);
      });
  }

  const onClickAddLocationButton = () => {
    redirectTo('/add');
    firebase.logAnalyticsEvent('AddLocation_Clicked');
  }

  const onClickShowSiteInfo = (site) => {
    redirectTo(`/site/${site.siteId}`);
    firebase.logAnalyticsEvent('ShowSiteInfo_Clicked');
  }

  return (
    <ThemeProvider theme={theme}>
          <AppBar position="static" color="default">
            <Toolbar>
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                onClick={handleMenu} >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                nuerdenfri.dk
          </Typography>
              <div>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose.bind(this, '/locations')}>Ladesteder</MenuItem>
                  <MenuItem onClick={handleClose.bind(this, '/info')}>Om</MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
        <div className={classes.root}>
          <Router ref={routerRef}>
              <Switch>
                  <Route exact path='/add' render={(props) => 
                    <LocationWizard {...props} 
                      dataProvider={dataProvider}
                      onSelectIWantToCharge={onSelectIWantToCharge}
                      onSelectIAmCharging={onSelectIAmCharging}
                      onSelectCancelSelectingSite={onSelectCancelSelectingSite}
                    />}
                  />
                  <Route exact path='/' render={(props) => 
                    <div>
                    <LocationWizard {...props} 
                      dataProvider={dataProvider}
                      onSelectIWantToCharge={onSelectIWantToCharge}
                      onSelectIAmCharging={onSelectIAmCharging}
                    />
                    <Fab {...props}
                      color="primary" aria-label="info" style={{ position: 'absolute', bottom: theme.spacing(2), right: theme.spacing(2) }}
                      onClick={onClickAddLocationButton}>
                      <AddOutlined />
                    </Fab>
                    </div>
                  }
                  />
                  <Route exact path='/locations' render={(props) =>
                    <div>
                    <NotificationsList {...props}
                      iWantToChargeNotifications={iWantToChargeNotifications}
                        iAmChargingNotifications={iAmChargingNotifications}
                        removeNotificationLocation={removeNotificationLocation}
                        disableNotificationLocation={disableNotificationLocation}
                        isLoadingNotifications={isLoadingNotifications}
                        onClickShowSiteInfo={onClickShowSiteInfo}
                    />
                    <Fab {...props}
                      color="primary" aria-label="info" style={{ position: 'absolute', bottom: theme.spacing(2), right: theme.spacing(2) }}
                      onClick={onClickAddLocationButton}>
                      <AddOutlined />
                    </Fab>
                    </div>
                  }
                  />
                  <Route exact path='/info' render={(props) =>
                    <div>
                    <Info {...props}
                      appInstanceId={appInstanceId}
                    />
                    <Fab {...props}
                      color="primary" aria-label="info" style={{ position: 'absolute', bottom: theme.spacing(2), right: theme.spacing(2) }}
                      onClick={onClickAddLocationButton}>
                      <AddOutlined />
                    </Fab>
                    </div>
                  }
                  />
                  <Route exact path='/site/:siteId' render={(props) => 
                    <SiteInfo {...props}
                      dataProvider={dataProvider}
                      redirectTo={redirectTo}
                    />
                  } />
            </Switch>
          </Router>
        </div>
          {!!messageDialog ?
            <MessageDialog
              open={(!!messageDialog)}
              title={messageDialog && messageDialog.title}
              body={messageDialog && messageDialog.body}
              onClose={messageDialog && messageDialog.onClose}
              ringBell={messageDialog && messageDialog.ringBell}
            />
            : ''}
          {!!enterEmailMessageDialog ?
            <MessageEnterEmailDialog
              open={(!!enterEmailMessageDialog)}
              title={enterEmailMessageDialog && enterEmailMessageDialog.title}
              body={enterEmailMessageDialog && enterEmailMessageDialog.body}
              onClose={enterEmailMessageDialog && enterEmailMessageDialog.onClose}
            />
            : ''}

    </ThemeProvider>
  );
}

export default App /*we export to access other files.*/
class DataProvider {
    constructor(firebase) {
        this.firebase = firebase;
        //window.onerror = this.logError;
        this.dev = false;
        this.devUrlPostfix = this.dev ? 'http://localhost:4001/nuerdenfri/us-central1' : '';
        //this.devUrlPostfix = this.dev ? 'https://us-central1-nuerdenfri.cloudfunctions.net' : '';
    }
    logError = (message, source, lineno, colno, error)  => {
        console.log("POSTING to /logError");
        fetch(this.devUrlPostfix + '/logError', {
            method: 'POST', 
            body: JSON.stringify({message, source, lineno, colno, error}),
            headers: {'Content-Type': 'application/json'},
            mode: this.dev ? 'no-cors' : 'cors'
        })
    }

    MOCKgetAllSites = () => {
        return new Promise( (resolve, reject) => {
            resolve({
                    "sites": [
                        {
                            "lat": 55.680146,
                            "nocs": 1,
                            "address": "Rødovre Centrum 202, 2610, Rødovre",
                            "lng": 12.458586,
                            "name": "Rødovre Centrum - Rød P. -2 #6",
                            "id": "EONDK_00403"
                        },
                        {
                            "lat": 55.680146,
                            "nocs": 1,
                            "address": "Rødovre Centrum 202, 2610, Rødovre",
                            "lng": 12.458586,
                            "name": "Rødovre Centrum - Rød P. -2 #5",
                            "id": "EONDK_00404"
                        },
                        {
                            "name": "Rødovre Centrum - Rød P. -2 #2",
                            "id": "EONDK_00405",
                            "lat": 55.680146,
                            "nocs": 1,
                            "address": "Rødovre Centrum 202, 2610, Rødovre",
                            "lng": 12.458586
                        }
                    ]
                });
        });
    }

    remap = (data) => {
        let remapped = [];
        let dayHourFoldedIndex = 0;
        for (let day = 0; day < 7; day++){
            for(let hour = 0; hour < 24; hour++) {
                if(!remapped[6-day])
                    remapped[6-day] = [];
                remapped[6-day][hour] = data[dayHourFoldedIndex];
                dayHourFoldedIndex++;
            }
        }
        return remapped;
    }

    getSiteCurrentChargingInfo = (siteId) => {
        return new Promise((resolve, reject) => {
            let db = this.firebase.firestore;
            db.collection('siteStatusShort').doc(siteId).get()
            .then(doc => {
                let json= doc.data();
                const now = Math.floor((new Date()).getTime()/1000);
                let ret = {currentChargingSessions: [], currentFreeChargers:[], loaded: true, name: json.name, address: json.address};
                if(json.currentChargingSessions)
                    ret.currentChargingSessions = json.currentChargingSessions.map(v => now-v).map(d => (d < 3600) ? `${Math.floor(d/60)}m` : `${Math.floor(d/3600)}t.`)

                if(json.currentFreeChargers)
                    ret.currentFreeChargers = json.currentFreeChargers.map(v => now-v).map(d => (d < 3600) ? `${Math.floor(d/60)}m` : `${Math.floor(d/3600)}t.`)

                ret.noOfChargers = ret.currentChargingSessions.length + ret.currentFreeChargers.length;
                ret.hasBothFreeAndOccupiedChargers = ret.currentChargingSessions.length > 0 &&  ret.currentFreeChargers.length > 0;
                ret.dataAnyFree = json.dataAnyFree && this.remap(json.dataAnyFree);

                resolve(ret);
            });
        });
    }

    getAllSites = () => {
        return new Promise((resolve, reject) => {
            let db = this.firebase.firestore;
            db.collection('allSites').doc("eon").get()
            .then(doc => {
                let eonSites = doc.data();
                resolve(eonSites);
            });
        });
    }

    getAppInstanceId = () => {
        return new Promise((resolve, reject) => {

            const localStorageKey = "appInstanceId";
            let appInstanceId = localStorage.getItem(localStorageKey);

            if (appInstanceId) {
                console.log("Loaded appInstanceId from promise: ", appInstanceId);
                resolve(appInstanceId);
                return;
            }

            const instanceIdPromise = fetch(this.devUrlPostfix + '/getInstanceId');
            instanceIdPromise.then(resp => {
                resp.json().then(json => {
                    console.log("Got instanceId: ", json.instanceId);
                    localStorage.setItem(localStorageKey, json.instanceId);
                    resolve(json.instanceId);
                });
            });
        });
    }

    removeNotificationLocation = (req, callback) => {
        return new Promise( (resolve, reject) => {
            //console.log("Calling removeNotificationLocation with req:", req);
            console.log("Calling removeNotificationLocation");
            fetch(this.devUrlPostfix + '/removeNotificationLocation', {
                method: 'POST', 
                body: JSON.stringify(req),
                headers: {'Content-Type': 'application/json'},
                mode: this.dev ? 'no-cors' : 'cors'
            })
            .then((res) => {
                res.json().then(json => {
                    console.log("Got status: ", json.status);
                    resolve();
                });
            });
        });
    }

    setEmail = (aii, emailAddress) => {
        console.log(`setEmail = (${aii}, ${emailAddress})`);
        const req = {applicationId: aii, emailAddress: emailAddress};
        return new Promise( (resolve, reject) => {
            console.log("Calling setEmail with req:", req);
            fetch(this.devUrlPostfix + '/setEmail', { method: 'POST', headers: {'Content-Type': 'application/json'}, mode: this.dev ? 'no-cors' : 'cors', body: JSON.stringify(req) })
            .then((res) => {
                console.log("SetEmail status: ", res.status);
                (res.status === 200) ? resolve() : reject();
            });
        });
    }

    setDisabledNotificationLocation = (req) => {
        return new Promise( (resolve, reject) => {
            console.log(`Calling setDisabledNotificationLocation with ${JSON.stringify(req)}`);
            fetch(this.devUrlPostfix + '/setDisabledNotificationLocation', { method: 'POST', headers: {'Content-Type': 'application/json'}, mode: this.dev ? 'no-cors' : 'cors', body: JSON.stringify(req) })
            .then((res) => {
                console.log("SetDisabledNotificationLocation status: ", res.status);
                (res.status === 200) ? resolve() : reject();
            });
        });
    }

    addNotificationLocation = (req, callback) => {

        console.log("Calling addNotificationLocation with req:", req);
        fetch(this.devUrlPostfix + '/addNotificationLocation', {
            method: 'POST', 
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(req),
            mode: this.dev ? 'no-cors' : 'cors'
        })
        .then((res) => {
            res.json().then(json => {
                console.log("Got status: ", JSON.stringify(json));
                callback();
            });
        });
    }

    withNotificationToken = (callback) => {
        this.firebase.getToken().then(function(currentToken) {
            if (currentToken) {

                console.log("Currenttoken: ", currentToken);
                callback(currentToken);
            } else {
                this.requestPermission(callback);
            }
        }.bind(this)).catch(function(err) {
            console.error('Unable to get messaging token.', err);
            if (err.code === 'messaging/permission-default') {
            this.fcmErrorContainer.innerText = 'You have not enabled notifications on this browser. To enable notifications reload the page and allow notifications using the permission dialog.';
            } else if (err.code === 'messaging/notifications-blocked') {
            this.fcmErrorContainer.innerHTML = 'You have blocked notifications on this browser. To enable notifications follow these instructions: <a href="https://support.google.com/chrome/answer/114662?visit_id=1-636150657126357237-2267048771&rd=1&co=GENIE.Platform%3DAndroid&oco=1">Android Chrome Instructions</a><a href="https://support.google.com/chrome/answer/6148059">Desktop Chrome Instructions</a>';
            }
        }.bind(this));
    }

    requestPermission = (callback) => {
        console.log('Requesting permission...');
        this.firebase.requestPermission().then(function() {
            this.firebase.getToken().then(function(currentToken) {
                console.log('Notification permission granted.');
                callback(currentToken);
            });
            //saveToken();
        }).catch(function(err) {
            console.error('Unable to get permission to notify.', err);
        });
    }
}

export default DataProvider;